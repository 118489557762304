import React from "react"
import Layout from "../components/layout"
import { Card } from "react-bootstrap"
import { Helmet } from "react-helmet"
import AudioPlayer from "react-h5-audio-player"
import "react-h5-audio-player/lib/styles.css"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

const getImages = graphql`
  {
    fluid: file(relativePath: { eq: "bookQOL.JPG" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    footer: file(relativePath: { eq: "TheatreAndPeaceBuilding.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    test: allContentfulPodcast {
      edges {
        node {
          item {
            id
            file {
              url
              fileName
            }
          }
        }
      }
    }
  }
`

const Podcast = () => {
  const data = useStaticQuery(getImages)
  const getMP3 = name => {
    const x = data.test.edges.find(
      item => item.node.item.file.fileName === name
    )
    return x?.node?.item?.file?.url
  }
  return (
    <Layout>
      <Helmet>
        <title>Podcasts - Pamela Mary Brown</title>
      </Helmet>
      <h2>Podcasts</h2>

      <Card style={{ border: "none", backgroundColor: "transparent" }}>
        <Card.Title>
          <b>Questions of Legacy </b>
        </Card.Title>
        <div style={{ maxHeight: `450px`, marginBottom: `1.45rem` }}>
          <Image
            fluid={data.fluid.childImageSharp.fluid}
            alt={"Questions of legacy book cover"}
            style={{ height: "440px", width: "100%" }}
            imgStyle={{ objectFit: "contain" }}
          />
        </div>

        <Card.Text>
          <i>The Theatre Peace Building Academy </i>project came to an end in
          September 2020. <i>Questions of Legacy</i> was an umbrella project
          that created a ‘narrative archive’ demonstrating how the production of
          podcasts (with accompanying publication) promotes understanding,
          empathy and reconciliation. The interviews demonstrate how mutual
          listening creates a dialogue about the past in the present towards the
          path forward.
        </Card.Text>

        <Card.Text>
          Six members from the Belfast WAVE trauma group took part in the
          podcast interviews. Carefully selected poems were used in discussion
          to springboard ‘legacy’ questions. Significantly, Primo Levi’s ‘The
          Survivor’ and a contemporary piece by a son writing about his father
          having lost a leg due to a punishment beating. The participant’s
          response to both poems was extraordinary.
        </Card.Text>
        <div className="wrapper text-center">
          <div style={{ margin: "0 auto", width: "70%" }}>
            <AudioPlayer
              autoPlay={false}
              src={getMP3("WAVEmembers.mp3")}
              header="WAVE members Poetry Discussion"
              style={{
                backgroundColor: "transparent",
                border: "2px solid",
              }}
            />
          </div>
        </div>
        <hr style={{ background: "black" }} />
        <Card.Text style={{ marginTop: "16px" }}>
          <b> SELECTING THE QUESTIONS: </b>36 questions; twelve from the past,
          twelve from the present, and twelve about the future. During the
          interviews—while it was a collective endeavour in the creation of the
          questions—no participant knew which question they would be asked.
          These were selected at random from cards, ensuring a spontaneous and
          unrehearsed response.
        </Card.Text>

        <Card.Text>
          The interviews were peer-led, pairing-off the participants to
          interview each other. I had several reasons for this approach, and
          primarily due to the fact that the participants were aware of the
          nuances of each other’s story. They could draw out essential detail
          during the recordings. The podcasts were transcribed for an
          accompanying book. Composition of music for podcasts in conjunction
          with composer Connor Kelly.
        </Card.Text>

        <Card.Text>
          Jennifer McNern, Alex Bunting, Mark Kelly, Margaret Yeaman, Peter
          Heathwood and Paul Gallagher, members of WAVE Trauma, Belfast and
          civilians injured during <i>the Troubles. </i> Their voices are from
          before and after these direct encounters: the beginning of a day’s
          work, having a cup of coffee, or sitting at home with family watching
          TV. The ordinary flow of reality shattered by the hyper-reality of
          violence, and their voices alongside their journeys to endure beyond
          hate, existing and living with their extreme injuries and the chaos;
          finding new life, humour, and approximating ‘normality’ with
          perseverance and fortitude.    
        </Card.Text>

        <Card.Text>
          <b>Jennifer McNern </b> on the fourth of March 1972 was injured by a
          no warning bomb in the Abercorn restaurant in Belfast City Centre. She
          lost both her legs and had other serious fractures to her body.
        </Card.Text>

        <div className="wrapper text-center">
          <div style={{ margin: "0 auto", width: "70%" }}>
            <AudioPlayer
              autoPlay={false}
              src={getMP3("JenniferMcNern.mp3")}
              header="Jennifer McNern interviewed by Margaret Yeaman"
              style={{
                backgroundColor: "transparent",
                border: "2px solid",
              }}
            />
          </div>
        </div>
        <hr style={{ background: "black" }} />
        <Card.Text style={{ marginTop: "16px" }}>
          <b> PAST:</b> What supports were in place when you were injured? Do
          you ever think ‘what if?’ and ‘why me?’ <b>PRESENT: </b> Injured and
          disabled victims by atrocities during <i>the troubles </i> are
          considered low grade inquires, not important enough for investigation.
          Agree or disagree? Is there a question centred upon what happened to
          you that continually nags you day and night? <b> FUTURE: </b>When is
          it okay to stop talking about something? How can your voice carry into
          the future?
        </Card.Text>
        <Card.Text>
          <b>Alex Bunting </b> was blown up on the twenty-first of the tenth
          1991, he lost his left leg, and sustained injury to his right leg, and
          the shrapnel injuries caused by the bomb that was planted underneath
          his taxi while out working.
        </Card.Text>

        <div className="wrapper text-center">
          <div style={{ margin: "0 auto", width: "70%" }}>
            <AudioPlayer
              autoPlay={false}
              src={getMP3("AlexBunting.mp3")}
              header="Alex Bunting interviewed by Peter Heathwood"
              style={{
                backgroundColor: "transparent",
                border: "2px solid",
              }}
            />
          </div>
        </div>
        <hr style={{ background: "black" }} />
        <Card.Text style={{ marginTop: "16px" }}>
          <b>PAST: </b> How have you survived on top of surviving? How big a
          part of your story is loss? <b>PRESENT: </b> Do you think the
          experience the atrocities visited upon you has made you a more
          determined person? What qualities are the most important to survive a
          conflict related trauma? <b>FUTURE: </b> Do you think there should be
          a truth and reconciliation process? What do you think would help
          society move forward?
        </Card.Text>
        <Card.Text>
          <b>Mark Kelly </b>on the twenty-eight of the eighth, 1976 was caught
          up in a no warning bomb explosion in the Glen Inn, Glengormley. As a
          consequence, he is a bilateral amputee of the lower limbs, losing one
          leg below the knee and the other above the knee. His other injuries
          were burns, shrapnel, and a compound fracture of the femur, hearing
          loss and lung damage.
        </Card.Text>

        <div className="wrapper text-center">
          <div style={{ margin: "0 auto", width: "70%" }}>
            <AudioPlayer
              autoPlay={false}
              src={getMP3("MK.mp3")}
              header="Mark Kelly interviewed by Paul Gallagher"
              style={{
                backgroundColor: "transparent",
                border: "2px solid",
              }}
            />
          </div>
        </div>
        <hr style={{ background: "black" }} />
        <Card.Text style={{ marginTop: "16px" }}>
          <b>PAST: </b> Who has ownership of the past? Is there a stigma
          attached to your injuries in terms of victim blaming? <b>PRESENT: </b>
          Have you a carer and can you talk about your support network? Your
          injuries form a bridge between your life before and your life
          after…what is the present for you? <b>FUTURE: </b> How does your story
          and the campaign impact upon the future? What are your hopes and fears
          for the future?
        </Card.Text>
        <Card.Text>
          <b>Margaret Yeaman </b> on the 15 March 1982, was in work in an estate
          agents in Banbridge. There was a no-warning car bomb. Margaret lost
          her sight.
        </Card.Text>
        <div className="wrapper text-center">
          <div style={{ margin: "0 auto", width: "70%" }}>
            <AudioPlayer
              autoPlay={false}
              src={getMP3("MY.mp3")}
              header="Margaret Yeaman interviewed by Jennifer McNern"
              style={{
                backgroundColor: "transparent",
                border: "2px solid",
              }}
            />
          </div>
        </div>
        <hr style={{ background: "black" }} />
        <Card.Text style={{ marginTop: "16px" }}>
          <b> PAST:</b> When did you first become aware that you were caught up
          in a traumatic event? What extremes have you had to face because of
          what happened to you? <b>PRESENT: </b> Has anything positive come out
          of what happened to you? In terms of the current fragile ‘peace’, what
          have we not learned? <b>FUTURE: </b> What happens your carer, or
          carers, if something happens you? What are your thoughts on
          reconciliation?
        </Card.Text>
        <Card.Text>
          <b>Peter Heathwood </b>on the twenty seventh of September 1979 was
          attacked by two loyalist gunmen from the UFF who entered his North
          Belfast home and shot him after a struggle. His father died of a heart
          attack at the scene. Peter is a T-6 incomplete paraplegic and uses a
          wheelchair from that day onwards.
        </Card.Text>
        <div className="wrapper text-center">
          <div style={{ margin: "0 auto", width: "70%" }}>
            <AudioPlayer
              autoPlay={false}
              src={getMP3("PH.mp3")}
              header="Peter Heathwood interviewed by Alex Bunting"
              style={{
                backgroundColor: "transparent",
                border: "2px solid",
              }}
            />
          </div>
        </div>
        <hr style={{ background: "black" }} />
        <Card.Text style={{ marginTop: "16px" }}>
          <b> PAST:</b> What has the ripples effect of <i>The Troubles </i>{" "}
          been? Can you recall the first moment <i> The Troubles </i>impacted
          your life? <b>PRESENT: </b> How do you define or categorize yourself
          in relation to your injuries during the troubles? Decades after{" "}
          <i>the troubles, </i> in the peace process era, what do you feel about
          interface areas and community? <b> FUTURE: </b>
          What is next for you in terms of your campaign? CAIN—a memory bank of
          stores, images and data collected during the Troubles is currently
          under threat of closure. Why is it important to secure funding to
          maintain the archive?
        </Card.Text>
        <Card.Text>
          <b>Paul Gallagher </b> was injured on the sixth of January 1994. His
          home was taken over by four gunmen who shot him six times. He
          sustained damage to his lung, lost his spleen, sustained a fractured
          femur; one bullet hit his spine leaving him paralysed.
        </Card.Text>
        <div className="wrapper text-center">
          <div style={{ margin: "0 auto", width: "70%" }}>
            <AudioPlayer
              autoPlay={false}
              src={getMP3("PG.mp3")}
              header="Paul Gallagher interviewed by Mark Kelly"
              style={{
                backgroundColor: "transparent",
                border: "2px solid",
              }}
            />
          </div>
        </div>
        <hr style={{ background: "black" }} />
        <Card.Text style={{ marginTop: "16px" }}>
          <b>PAST: </b> Are your injuries responsible for a life that you never
          anticipated? Where did you go to get anyone to listen to you?{" "}
          <b>PRESENT: </b>
          What made your group a success? Are victims ignored? This includes
          neighbours, institutions, society and your position. <b>FUTURE: </b>
          What way can your voice and your story influence a more positive path
          towards the future? What are your hopes for the next generation?
        </Card.Text>
        <Card.Text>
          <b>Additional Podcast: Post Traumatic Growth</b>
        </Card.Text>

        <div className="wrapper text-center">
          <div style={{ margin: "0 auto", width: "70%" }}>
            <AudioPlayer
              autoPlay={false}
              src={getMP3("PGG.mp3")}
              header="Paul Gallagher talks about Post Traumatic Growth"
              style={{
                backgroundColor: "transparent",
                border: "2px solid",
              }}
            />
          </div>
        </div>

        <Card.Text style={{ marginTop: "16px" }}>
          The opportunity to work with the WAVE Group and record their stories
          was a transforming experience. Stories, comments, anecdotes,
          struggles, hopes, fears, honesty, compassion; many things stood out:
          notably the lack of bitterness and the all-encompassing humanity that
          the group had for all people in their communities. Everybody is part
          of their community, and they make no distinctions. The experiences and
          stories shared by this group, show how the people who speak the
          loudest have not always the most important thing to say, and if we
          want to learn about each other, about our differences, our
          vulnerability, and our ability to be compassionate, to really care
          about each other in conflict, then the voices of these survivors are a
          guide for society seeking to advance the Peace Process.
        </Card.Text>

        <div style={{ maxHeight: `450px` }}>
          <Image
            fluid={data.footer.childImageSharp.fluid}
            alt={"Theatre And Peace Building"}
            style={{ height: "200px", width: "100%" }}
            imgStyle={{ objectFit: "contain" }}
          />
        </div>
      </Card>
    </Layout>
  )
}

export default Podcast
